import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Heading from "../zzz/organisms/heading"
import { path } from "../lib/util"
import BlogLayout from "../zzz/organisms/blog"
import { LinkWrapper as Link } from "../utils/linkWrapper"

// The main blog page listing all the posts
const IndexPage = ({ location, data, pageContext }) => {
  const { numOfPages, pageNumber, category, ids } = pageContext

  const categoryData = data.localWpGraphQlCategories
  const postData = data.localWpGraphQlPages
  const blogPosts = data.allLocalWpGraphQlPosts.nodes

  const post_id = postData.pageId
  const seo = postData.seo
  const title = postData.title == "Blog" ? "Sleep Matters" : postData.title

  const blogPageNumber = pageNumber > 1 ? ` - Page ${pageNumber}` : ``
  const categorySlug = category ? category.slug : ``
  const subTitle = postData.heading.subTitle
  const pageTitle = category ? `All Posts in ${category.name}` : `All Posts`

  const categorySeoTitle = category ? category.name : false
  let categoryMetaDesc = false
  if (category) {
    categoryMetaDesc = `Browse our ${category.name} category which offers useful advice on all sleep-related topics, from choosing the right bed to mattress maintenance.`
  }

  const titleOverload = `${
    categorySeoTitle ? categorySeoTitle : title
  }${blogPageNumber}`

  let fluidBackground = path(
    ["Image", "image", "imageFile", "localFile", "childImageSharp", "fluid"],
    categoryData
  )
  fluidBackground =
    fluidBackground ||
    path(
      ["featuredImage", "imageFile", "localFile", "childImageSharp", "fluid"],
      postData
    )

  return (
    <Layout pageNumber={pageNumber} location={location} background="#fafafa">
      <SEO
        overwriteYoastTitle={categorySeoTitle || pageNumber > 1 ? true : false}
        title={titleOverload}
        overwriteYoastMetaDescription={!!categoryMetaDesc}
        description={categoryMetaDesc}
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <Heading
        titleOverload={titleOverload}
        subTitle={subTitle}
        location={location}
        fluid={fluidBackground}
      />
      <BlogLayout
        posts={blogPosts}
        pageNumber={pageNumber}
        pageTitle={pageTitle}
        categorySlug={categorySlug}
        numberOfPages={numOfPages}
        location={location}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query GET_POSTS($ids: [Int], $catslug: String) {
    allLocalWpGraphQlPosts(filter: { postId: { in: $ids } }) {
      nodes {
        id
        title
        uri
        slug
        date
        content
        excerpt
        author {
          name
          slug
          avatar {
            url
          }
        }
        featuredImage {
          sourceUrl
          imageFile {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    localWpGraphQlCategories(slug: { eq: $catslug }) {
      slug
      name
      Image {
        image {
          sourceUrl
          imageFile {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    localWpGraphQlPages(pageId: { eq: 51508 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      pageId
      title
      featuredImage {
        sourceUrl
        imageFile {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      heading {
        subTitle
      }
    }
  }
`

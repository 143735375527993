import React from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowLeft from "../../atoms/icons/files/arrow-left.jsx"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"

const Pagination = ({
  pageNumber,
  numberOfPages,
  baseURL = "/sleep-matters/",
}) => {
  const hasNext = pageNumber < numberOfPages
  const hasPrevious = pageNumber > 1
  const nextUrl = baseURL + "page-" + (pageNumber + 1)
  const previousUrl =
    pageNumber === 2 ? baseURL : baseURL + "page-" + (pageNumber - 1)

  return (
    <Wrap>
      <Left>
        <Button
          disabled={!hasPrevious}
          direction="left"
          SvgComponent={ArrowLeft}
          to={previousUrl}
          label="Previous"
        />
      </Left>
      <Center className="center">
        <Numbers
          pageNumber={pageNumber}
          numberOfPages={numberOfPages}
          baseURL={baseURL}
        />
      </Center>
      <Right>
        <Button
          disabled={!hasNext}
          direction="right"
          SvgComponent={ArrowRight}
          to={nextUrl}
          label="Next"
        />
      </Right>
    </Wrap>
  )
}

const Numbers = ({
  pageNumber,
  numberOfPages,
  baseURL = "/sleep-matters/",
}) => {
  if (numberOfPages === 1) return null
  const eqCurPage = (n) => n === pageNumber
  const buildLink = (num) => (num === 1 ? baseURL : baseURL + "page-" + num)
  let pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1)

  if (pageNumbers.length > 4) {
    const idx = pageNumbers.indexOf(pageNumber)
    const head = pageNumbers.slice(idx - 2 < 0 ? 0 : idx - 2, idx)
    const tail = pageNumbers.slice(idx + 1, idx + 3)
    pageNumbers = [...head, pageNumber, ...tail]
  }

  return (
    <>
      <MobileNumberIndicator>
        page {pageNumber} of {numberOfPages}
      </MobileNumberIndicator>
      {pageNumber > 3 && <Number to={buildLink(1)}>1</Number>}
      {pageNumber > 4 && <NumberBlank as="div">...</NumberBlank>}
      {pageNumbers.map((n) => (
        <Number to={buildLink(n)} key={n} className={eqCurPage(n) && "active"}>
          {n}
        </Number>
      ))}
      {numberOfPages - pageNumber > 3 && (
        <NumberBlank as="div">...</NumberBlank>
      )}
      {numberOfPages - pageNumber > 2 && (
        <Number to={buildLink(numberOfPages)}>{numberOfPages}</Number>
      )}
    </>
  )
}

const MobileNumberIndicator = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: flex;
    align-items: center;
    color: white;
  }
`

const Button = ({ SvgComponent, direction, to, label, disabled }) => {
  const elements = [
    <SvgIcon
      SvgComponent={SvgComponent}
      hue="danger"
      shade="fontColor"
      key="icon"
    />,
    <div key="label">{label}</div>,
  ]
  const isLeft = direction === "left"

  const StyledButton = styled(Link)`
    border-radius: 3px;
    overflow: hidden;
    background-color: #3e4b5c;
    color: white;
    display: flex;
    width: 200px;
    justify-content: space-between;
    transition: 0.2s all ease-in-out;
    &:hover {
      &.left {
        transform: translateX(-3px);
      }
      &.right {
        transform: translateX(3px);
      }
    }

    ${disabled
      ? `
		pointer-events: none;
		background: #162232;
		color:#4c5e75;
		> span.icon {
			background: #162232;
			svg g#icon {
				fill:#4c5e75;
			}
		}
	`
      : ``}

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > span {
      padding: 10px;
      background: #d91a1f;
    }
  `

  return (
    <StyledButton className={direction} to={to} as={disabled ? "span" : Link}>
      {isLeft ? elements : [elements[1], elements[0]]}
    </StyledButton>
  )
}

const Wrap = styled.div`
  background-color: #162232;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .left,
    .right {
      width: auto;
      div {
        display: none;
      }
    }
  }
`

const Left = styled.div``
const Center = styled.div`
  display: flex;
`
const Right = styled.div``

const NumberBlank = styled.div`
  color: white;
  padding: 10px 5px;
  transition: 0.2s all ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

const Number = styled(Link)`
  background-color: #3e4b5c;
  border-radius: 3px;
  color: white;
  padding: 10px;
  margin: 0 3px;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
  &.active {
    background: #d91a1f;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

export default Pagination
